import React, {
  useRef,
  useState
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import { logout } from '../../../actions/accountActions';
import AccountObj from '../../../types/Account';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

export default function Account() {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const account = useSelector((state: AccountObj) => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  const userName = account.user.firstName
      ? `${ account.user.firstName } ${ account.user.lastName }`
      : account.user.email;
  const photoUrl = `${account.user.photoUrl}?${Date.now()}`;
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(logout());
      history.push('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
        autoHideDuration: 2000,
      });
    }
  };

  return (
    <>
      <div ref={ref}>
         <Box
           display="flex"
           alignItems="center"
           component={ButtonBase}
           onClick={handleOpen}
         >
           <Avatar
             alt="User"
             className={classes.avatar}
             src={photoUrl}
           />
           <Hidden smDown>
             <Typography
               variant="h6"
               color="inherit"
             >
               {userName}
             </Typography>
           </Hidden>
         </Box>
      </div>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
            onClick={handleClose}
          component={RouterLink}
          to="/app/social/profile"
        >
          <FormattedMessage id="app:TopBar;Account;Profile;title"
                            defaultMessage="Profile"/>
        </MenuItem>
        <MenuItem
            onClick={handleClose}
          component={RouterLink}
          to="/app/account"
        >
          <FormattedMessage id="app:TopBar;Account;account;title"
                            defaultMessage="Account"/>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <FormattedMessage id="app:TopBar;Account;logOut;title"
                            defaultMessage="Account"/>
        </MenuItem>
      </Menu>
    </>
  );
}

