import Page from '../components/Page';
import React from 'react';
import {Box, Container, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {ThemConfig} from "../types/Them";
import Logo from "../components/Logo";

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme: ThemConfig) => ({
  root: {
    minHeight: '100%',
  }
}));


// ----------------------------------------------------------------------

export default function ErrorPage() {
  const classes = useStyles();

  return (
    <Page className={ classes.root } title="Ошибка">
        <Container style={{display: 'flex', height: '100vh'}}>
          <Box style={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <Logo/>

            <Typography style={{marginTop: 20, fontWeight: 'bold'}} variant="h3" color="textPrimary" paragraph>
              Произошла техническая ошибка
            </Typography>
            <Typography style={{ color: 'text.secondary' }}>Попробуйте позже или свяжитесь с информационным отделом</Typography>

          </Box>
        </Container>
    </Page>
  );
}
