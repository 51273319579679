import { Dispatch } from 'redux';

export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const SET_SEARCH = 'SET_SEARCH';

export function setSearch(searchValue: string) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({ type: CLEAR_SEARCH });

            await dispatch({
                type: SET_SEARCH,
                data: {
                    searchValue
                }
            });
        } catch (error) {
            throw error;
        }
    };
}

export function clearSearch() {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({ type: CLEAR_SEARCH });
        } catch (error) {
            throw error;
        }
    };
}
