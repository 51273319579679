import NavBarItem, { NavConfigItems } from '../types/NavBarItem';
import { BookOpen } from 'react-feather';
import navBarService from '../services/navBarService';

class NavBarHelper {
    getNavBarListByType = (type: string) => new Promise<NavConfigItems[]>(async (resolve, reject) => {
        const list: NavConfigItems[] = [];

        switch (type) {
            case 'SAP':
                const sapListNew = await navBarService.getSapList();
                sapListNew.forEach((menuItem: NavBarItem) => {
                    list.push({
                        title: menuItem.displayName,
                        href: `/app/directories/${ menuItem.name }`,
                    });
                });
                resolve(list);
                break;
            default:
                resolve(list);
        }
    });
}

const navBarHelper = new NavBarHelper();

export default navBarHelper;

