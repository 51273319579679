import { Dispatch } from 'redux';
import { SearchTemplateItem } from '../../types/public/SearchTemplate';

export const INITIALIZE_SEARCH_TEMPLATES = 'INITIALIZE_SEARCH_TEMPLATES';
export const SET_SEARCH_TEMPLATES = 'SET_SEARCH_TEMPLATES';
export const UPDATE_SEARCH_TEMPLATE = 'UPDATE_SEARCH_TEMPLATE';
export const CREATE_SEARCH_TEMPLATE = 'CREATE_SEARCH_TEMPLATE';
export const SWAP_SEARCH_TEMPLATES = 'SWAP_SEARCH_TEMPLATES';
export const REMOVE_SEARCH_TEMPLATES = 'REMOVE_SEARCH_TEMPLATES';

export function getSearchTemplateList() {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({ type: INITIALIZE_SEARCH_TEMPLATES });
            //const user: User = await authService.getCurrentUser();

            dispatch({
                type: SET_SEARCH_TEMPLATES,
                data: {
                    searchTemplates: []
                }
            });
        } catch (error) {
            dispatch({ type: INITIALIZE_SEARCH_TEMPLATES });
            throw error;
        }
    };
}

export function updateSearchTemplate(searchTemplate: SearchTemplateItem, index: number) {
    return async (dispatch: Dispatch) => {
        try {
            await dispatch({
                type: UPDATE_SEARCH_TEMPLATE,
                data: {
                    searchTemplate,
                    index
                }
            });
        } catch (error) {
            throw error;
        }
    };
}

export function swapSearchTemplates(index: number, position: number) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({
                type: SWAP_SEARCH_TEMPLATES,
                data: {
                    index,
                    position
                }
            });
        } catch (error) {
            throw error;
        }
    };
}

export function createNewSearchTemplate(searchTemplate: SearchTemplateItem) {
    return async (dispatch: Dispatch) => {
        try {
            await dispatch({
                type: CREATE_SEARCH_TEMPLATE,
                data: {
                    searchTemplate
                }
            });
        } catch (error) {
            throw error;
        }
    };
}

export function removeSearchTemplates(searchTemplates: (string | undefined)[]) {
    return async (dispatch: Dispatch) => {
        try {
            await dispatch({
                type: REMOVE_SEARCH_TEMPLATES,
                data: {
                    searchTemplates
                }
            });
        } catch (error) {
            throw error;
        }
    };
}
