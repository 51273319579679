import jwtDecode from 'jwt-decode';
import _, { isEqual } from 'lodash';
import Tender from '../types/Tender';
import { AvailableRoles, TenderStatus, UserActions } from '../constants/enums';
import Material from '../types/Material';
import trackService from '../services/trackService';

export function generatePassword() {
  const length = 8;
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

export function allowOnlyNumbers(value: string): boolean {
  const re = /^\d*\.?\d*$/;
  return value === '' || re.test(value);
}

export function isFullGroupSelected(group: Material[], selectedItems: Material[]) {
  let count = 0;
  if (group) {
    group.map(groupItem => {
      const match = selectedItems.find(item => {
        if (item.sapApplicationNumber) {
          return item.sapApplicationNumber === groupItem.sapApplicationNumber;
        } else {
          return item.similarSAPWorkItem.find(item => item.sapApplicationNumber === groupItem.sapApplicationNumber);
        }
      });
      if (Boolean(match)) {
        count++;
      }
    });
    return group.length === count;
  } else {
    return false;
  }
}

export function prepareRequestItems(items: Material[]) {
  const preparedData: Material[] = [];
  items.map(item => {
    if (item.sapApplicationNumber) {
      preparedData.push(item);
    } else if (item.similarSAPWorkItem.length) {
      item.similarSAPWorkItem.map(similarItem => {
        preparedData.push(similarItem);
      });
    }
  });
  return preparedData;
}

export async function getImageFormData(file: Blob | string): Promise<FormData> {
  return new Promise(async (resolve, reject) => {
    const formData = new FormData();
    if (typeof file === 'string') {
      await getFile(file).then((data: Blob) => {
        formData.append('photo', data);
        resolve(formData);
      });
    } else {
      formData.append('photo', file);
      resolve(formData);
    }
  });
}

export function getUserRoles() {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    return jwtDecode(accessToken);
  }
  return {};
}

export function hasUserAccess(acceptRoles: string[]) {
  let rolesData: any = getUserRoles();
  if (!acceptRoles) {
    return true;
  }
  return _.some(acceptRoles, (item: string) => {
    return Boolean(rolesData[item]);
  });
}

export function getTenderUpdates(tenders: Tender[]) {
  const updatedTenders: any = {
    // isModalOpen: false,
    onRevision: [],
    readyToPublish: [],
    onVerification: [],
    pendingPaymentInfo: [],
    pendingPriceDeviation: [],
    oapInspection: [],
  };
  tenders.map((tender: Tender) => {
    switch (tender.status) {
      case TenderStatus.OnRevision:
        if (hasUserAccess([AvailableRoles.TENDER_EDIT])) {
          updatedTenders.onRevision.push(tender);
        }
        break;
      case TenderStatus.ReadyToPublish:
        if (hasUserAccess([AvailableRoles.TENDER_CREATE])) {
          updatedTenders.readyToPublish.push(tender);
        }
        break;
      case TenderStatus.OnVerification:
        if (hasUserAccess([AvailableRoles.TENDER_VERIFICATION])) {
          updatedTenders.onVerification.push(tender);
        }
        break;
      case TenderStatus.PendingPaymentInfo:
        if (hasUserAccess([AvailableRoles.TENDER_PENDING_PAYMENT_INFO])) {
          updatedTenders.pendingPaymentInfo.push(tender);
        }
        break;
      case TenderStatus.PendingPriceDeviation:
        if (hasUserAccess([AvailableRoles.TENDER_PENDING_PRICE_DEVIATION])) {
          updatedTenders.pendingPriceDeviation.push(tender);
        }
        break;
      case TenderStatus.OAPInspection:
        if (hasUserAccess([AvailableRoles.TENDER_OAP_INSPECTION])) {
          updatedTenders.oapInspection.push(tender);
        }
        break;
      default:
        return;
    }
  });
  return updatedTenders;
}

export function getUserRole() {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    let token: any = jwtDecode(accessToken);
    return token.role;
  }
  return {};
}

export function userHasOneOfRole(roleList: string[] = []) {
  const userRole = getUserRole();
  return roleList?.some((role: string) => userRole === role);
}

export async function getFile(url: string): Promise<Blob> {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.onload = function() {
      resolve(xhr.response);
    };
    xhr.send();
  });
}

export function downloadFile(data: string, name: string, type: string) {
  const url = window.URL.createObjectURL(new Blob([data], { type }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
}

export function base64ToFile(data: any, fileName: string, type?: string) {
  let arr = [];
  let mime = '';
  let bstr = '';
  if (type) {
    mime = type;
    bstr = atob(data);
  } else {
    arr = data.split(',');
    mime = arr && arr[0].match(/:(.*?);/)[1];
    bstr = atob(arr[1]);
  }

  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const file = new File([u8arr], fileName, { type: mime });
  downloadSelfUploadedFile(file, fileName);
}

export function base64ToString(str: string) {
  return decodeURIComponent(
    atob(str)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
}

export function downloadSelfUploadedFile(data: any, name: string) {
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
}

export const getSupplierStatus = (supplier: any) => {
  let status = '';
  if (
    supplier.reliabilityViolationCount === 0 &&
    supplier.itemsPricing === 'CorrespondMarket' &&
    supplier.oapAdmitted
  ) {
    if (supplier.rating === 1) {
      status = 'Рекомендуемый победитель';
    } else {
      status = `${supplier.rating}-е место`;
    }
  } else {
    switch (supplier.itemsPricing) {
      case 'OutOfRangeMarket':
        status = supplier.oapAdmitted
          ? `${supplier.rating}-е место (Допущен ОАП)\nНеконкурентная цена`
          : `Не допущен ОАП\nНеконкурентная цена`;
        break;
      case 'MissingProductInMarket':
        status = supplier.oapAdmitted
          ? `${supplier.rating}-е место (Допущен ОАП)\nНе полный ассортимент`
          : `Не допущен ОАП\nНе полный ассортимент`;
        break;
      case 'CorrespondMarket':
        status = supplier.oapAdmitted ? `${supplier.rating}-е место (Допущен ОАП)` : `Не допущен ОАП`;
        break;
    }
  }
  return status;
};

export function toBase64(file: any): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        const b64 = reader.result && reader.result.replace(/^data:.+;base64,/, '');
        resolve(b64);
      }
    };
    reader.onerror = error => reject(error);
  });
}

export function downloadCsv(data: string, name: string) {
  const hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
  hiddenElement.target = '_blank';
  hiddenElement.download = `${name}.csv`;
  hiddenElement.click();
}

export function normalizeData(data: any): any {
  let returnData: any = {};
  Object.keys(data).map(item => {
    const number = Number(data[item]);
    if (!isNaN(number)) {
      return (returnData[item] = number);
    }
    return (returnData[item] = data[item]);
  });
  return returnData;
}

export function translit(str: string): string {
  const space = '_';
  let objectKey = '';
  const transl: any = {
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    е: 'e',
    ё: 'e',
    ж: 'zh',
    з: 'z',
    и: 'i',
    й: 'j',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'h',
    ц: 'c',
    ч: 'ch',
    ш: 'sh',
    щ: 'sh',
    ъ: space,
    ы: 'y',
    ь: space,
    э: 'e',
    ю: 'yu',
    я: 'ya',
  };
  if (str != '') {
    str = str.toLowerCase();
  }

  for (let i = 0; i < str.length; i++) {
    if (/[а-яё]/.test(str.charAt(i))) {
      objectKey += transl[str.charAt(i)];
    } else if (/[a-z0-9]/.test(str.charAt(i))) {
      objectKey += str.charAt(i);
    } else {
      if (objectKey.slice(-1) !== space) {
        objectKey += space;
      }
    }
  }
  return objectKey;
}

export function toTranslitCamelcase(str: string): string {
  const converter = translit(str);
  return converter
    .replace(/_/g, ' ')
    .replace(/\w+/g, function(w) {
      return w[0].toUpperCase() + w.slice(1).toLowerCase();
    })
    .replace(/ /g, '');
}

export function currencyCheck(currency: string) {
  switch (currency) {
    case 'USD':
      return '$';
    case 'KZT':
      return '₸';
    case 'RUB':
      return '₽';
    case 'EUR':
      return '€';
    default:
      return '';
  }
}

export function daysTyping(value: any) {
  const string = value.toString();
  const lastChar = string.charAt(string.length - 1);

  if (lastChar == '1' && !(value == 11)) {
    return 'день';
  } else if (lastChar == '2' && !(value == 12)) {
    return 'дня';
  } else if (lastChar == '3' && !(value == 13)) {
    return 'дня';
  } else if (lastChar == '4' && !(value == 14)) {
    return 'дня';
  } else {
    return 'дней';
  }
}

export function hoursTyping(value: any) {
  const string = value.toString();
  const lastChar = string.charAt(string.length - 1);

  if (lastChar == '1' && !(value == 11)) {
    return 'час';
  } else if (lastChar == '2' && !(value == 12)) {
    return 'часа';
  } else if (lastChar == '3' && !(value == 13)) {
    return 'часа';
  } else if (lastChar == '4' && !(value == 14)) {
    return 'часа';
  } else {
    return 'часов';
  }
}

export function minutesTyping(value: any) {
  const string = value.toString();
  const lastChar = string.charAt(string.length - 1);

  if (lastChar == '1' && !(value == 11)) {
    return 'минута';
  } else if (lastChar == '2' && !(value == 12)) {
    return 'минуты';
  } else if (lastChar == '3' && !(value == 13)) {
    return 'минуты';
  } else if (lastChar == '4' && !(value == 14)) {
    return 'минуты';
  } else {
    return 'минут';
  }
}

export function getTitle(path: any) {
  switch (path) {
    case '/procurement':
      return 'Портал электронных закупок АО «УКТМК»';
    case '/activeTenders':
      return 'Тендеры в работе';
    case '/favorite':
      return 'Избранные тендеры';
    default:
      return '';
  }
}

export const saveApplyTenderTmpData = (tenderId: number, data: any) => {
  let tenders: any = {};
  const userEmail = localStorage.getItem('email');
  const savedTenders = localStorage.getItem('tenders');
  if (savedTenders) {
    tenders = JSON.parse(savedTenders);
  }
  if (userEmail) {
    tenders[userEmail] = {
      ...tenders[userEmail],
      [tenderId]: data,
    };
    localStorage.setItem('tenders', JSON.stringify(tenders));
    console.log('tenderData:', tenders[userEmail][tenderId].questionnaireData);
  }
};

export const clearApplyTenderTmpData = (tenderId: number) => saveApplyTenderTmpData(tenderId, {});
export const getApplyTenderTmpData = (tenderId: number) => {
  let tenders: any = {};
  const userEmail = localStorage.getItem('email');
  const savedTenders = localStorage.getItem('tenders');
  if (savedTenders) {
    tenders = JSON.parse(savedTenders);
  }
  if (userEmail) {
    return tenders[userEmail]?.[tenderId] || {};
  }
  return {};
};

export const getValueByRoleAccess = (roles: string[], value: any) => {
  if (hasUserAccess(roles)) {
    return value;
  }
  return '***';
};

export const getPriceValueByRoleAccess = (roles: string[], value: any) => {
  // if (hasUserAccess(roles)) {
  //   return value;
  // }
  // return '***';
  return value;
};

export const isDuplicateObj = (array: any[], objToCompare: any) => array.some(item => isEqual(item, objToCompare));

export const logUserAction = (actionType: UserActions, data?: any) =>
  trackService
    .logAction({
      payload: {
        screenName: actionType,
        ...data,
      },
    })
    .then(res => console.log(res));
