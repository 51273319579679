import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { createStyles, jssPreset, makeStyles, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import CookiesNotification from './components/CookiesNotification';
// import SettingsNotification from './components/SettingsNotification';
import GoogleAnalytics from './components/GoogleAnalytics';
import ScrollReset from './components/ScrollReset';
import useSettings from './hooks/useSettings';
import { createTheme } from './theme';
import Routes from './Routes';
import { IntlProvider } from 'react-intl-redux';
import { restoreLocale } from './utils/i18nHelpers';
import Loader from './components/Loader';
import TenderUpdateModal from './components/TenderUpdateModal';
import ErrorBoundary from './utils/ErrorBoundary';
import SupplierRequestedDocsModal from './components/SupplierRequestedDocsModal';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        height: '100%',
        width: '100%',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    },
  })
);

function App() {
  useStyles();
  // localStorage.setItem('count', '0');
  const { settings } = useSettings();

  return (
    <IntlProvider locale={restoreLocale(settings)}>
      <ThemeProvider theme={createTheme(settings)}>
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider maxSnack={1}>
              <Router history={history}>
                <ErrorBoundary>
                  <ScrollReset />
                  <GoogleAnalytics />
                  {/*<CookiesNotification />*/}
                  {/*<SettingsNotification />*/}
                  <Loader />
                  <TenderUpdateModal />
                  <SupplierRequestedDocsModal />
                  <Routes />
                </ErrorBoundary>
              </Router>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
