import { Dispatch } from 'redux';
import authService from '../../publicServices/authService';
import User from '../../types/public/User';
import { USER_PHOTO_UPLOADED } from '../accountActions';

export const P_LOGIN_REQUEST = 'P_LOGIN_REQUEST';
export const P_LOGIN_SUCCESS = 'P_LOGIN_SUCCESS';
export const P_LOGIN_FAILURE = 'P_LOGIN_FAILURE';
export const P_SILENT_LOGIN = 'P_SILENT_LOGIN';
export const P_LOGOUT = 'P_LOGOUT';
export const P_VERIFIED_USER = 'P_VERIFIED_USER';
export const P_USER_PHOTO_UPLOADED = 'P_USER_PHOTO_UPLOADED';

export function login(email: string, password: string, rememberMe: boolean) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({ type: P_LOGIN_REQUEST });
      const isVerified: Boolean = await authService.login(email, password, rememberMe);
      const user: User = await authService.getCurrentUser();

      dispatch({
        type: P_LOGIN_SUCCESS,
        data: {
          user: {
            ...user,
            isVerified
          }
        }
      });
    } catch (error) {
      dispatch({ type: P_LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user: User) {
  return (dispatch: Dispatch) => dispatch({
    type: P_SILENT_LOGIN,
    data: {
      user: {
        ...user
      }
    }
  });
}

export function logout() {
  return async (dispatch: Dispatch) => {
    authService.logout();
    dispatch({
      type: P_LOGOUT
    });
  };
}

export function uploadPhoto(photo: FormData) {
  return async (dispatch: Dispatch) => {
    try {
      const url: string = await authService.uploadPhoto(photo);
      await dispatch({
        type: P_USER_PHOTO_UPLOADED,
        data: {
          url
        }
      });
    } catch (error) {
      throw error;
    }
  };
}

export function removePhoto() {
  return async (dispatch: Dispatch) => {
    try {
      await authService.removePhoto();
      await dispatch({
        type: P_USER_PHOTO_UPLOADED,
        data: {
          url: '',
        }
      });
    } catch (error) {
      throw error;
    }
  };
}

export function verify(code: string, userId: number, email: string, option: string) {
  return async (dispatch: Dispatch) => {
    try {
      const isVerified: boolean = await authService.verify(code, userId, email, option);
        await dispatch({
          type: P_VERIFIED_USER,
          data: {
            isVerified
          }
        });
    } catch (error) {
      //dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}
