import { store } from '../store';
import { updateIntl } from 'react-intl-redux';
import en_Us from '../langs/en-US.json';
import ru from '../langs/ru.json';
import kz from '../langs/kz.json';
import { LANGS } from '../constants/enums';
import _ from 'lodash';
import { Settings } from '../types/Settings';

export const availableMessages = {
    [LANGS.EN]: en_Us,
    [LANGS.RU]: ru,
    [LANGS.KZ]: kz,
};

export function changeLanguage(language: LANGS, force = false): void {
    const state = store.getState();
    if (getCurrentLanguage() !== language
        || _.isEmpty(state.intl.messages)
        || force
    ) {
        updateLocals(language);
    }
}

/**
 * Get the intl locale (or the language being loaded)
 */
function getCurrentLanguage(): string {
    const state = store.getState();
    return state.intl.locale;
}

/**
 * Returns true if message with id exists in translations
 */
export function messageExists(
    props: any,
    id: string,
): boolean {
    return !!props.intl.messages[id];
}

function updateLocals(locale: LANGS) {
    store.dispatch(updateIntl({
        locale,
        messages: getMessages(locale),
    }))
}

export function getMessages(locale: LANGS) {
    return availableMessages[locale];
}


export function restoreLocale(settings: Settings = {}): string {
    const locale = settings.language || getCurrentLanguage();
    updateLocals(locale as LANGS);
    return locale;
}

export function translate(props: any, id: string, defaultMessage = '') {
    const { intl } = props;
    return intl.formatMessage({ id, defaultMessage });
}
