/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
    CREATE_SEARCH_TEMPLATE,
    REMOVE_SEARCH_TEMPLATES,
    INITIALIZE_SEARCH_TEMPLATES,
    SET_SEARCH_TEMPLATES,
    UPDATE_SEARCH_TEMPLATE,
    SWAP_SEARCH_TEMPLATES
} from '../../actions/public/searchTemplateActions';
import _ from 'lodash';
import { SearchTemplateItem } from '../../types/public/SearchTemplate';

interface Action {
    type: string;
    data: any;
}

interface State {
    searchTemplates: SearchTemplateItem[];
    searchTemplate?: SearchTemplateItem;
    index?: number;
}

const initialState: State = {
    searchTemplates: []
};

const searchTemplateReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case INITIALIZE_SEARCH_TEMPLATES: {
            return produce(state, (draft) => {
                draft.searchTemplates = [];
            });
        }

        case SET_SEARCH_TEMPLATES: {
            const { searchTemplates } = action.data;

            return produce(state, (draft) => {
                draft.searchTemplates = searchTemplates;
            });
        }

        case UPDATE_SEARCH_TEMPLATE: {
            const { searchTemplate, index } = action.data;

            return produce(state, (draft) => {
                if (searchTemplate && index !== undefined) {
                    const changedList = _.orderBy(draft.searchTemplates, ['order'], "asc");
                    changedList[index] = searchTemplate;
                    draft.searchTemplates = changedList;
                }
            });
        }

        case CREATE_SEARCH_TEMPLATE: {
            const { searchTemplate } = action.data;

            return produce(state, (draft) => {
                if (searchTemplate) {
                    draft.searchTemplates = [...draft.searchTemplates, searchTemplate];
                }
            });
        }

        case SWAP_SEARCH_TEMPLATES: {
            return produce(state, (draft) => {
                const changedList = _.orderBy(draft.searchTemplates, ['order'], "asc");
                const currentEl = changedList[action.data.index];
                const nextEl = changedList[action.data.index + action.data.position];
                currentEl.order = nextEl.order;
                nextEl.order = action.data.index;

                draft.searchTemplates = changedList;
            });
        }

        case REMOVE_SEARCH_TEMPLATES: {
            return produce(state, (draft ) => {
                const { searchTemplates } = action.data;
                draft.searchTemplates = draft.searchTemplates.filter((template: SearchTemplateItem) => {
                    if (searchTemplates.includes(template._id)) {
                        return false;
                    }
                    return true;
                });
            });
        }

        default: {
            return state;
        }
    }
};

export default searchTemplateReducer;
