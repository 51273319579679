/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  Book,
  BookOpen,
  Calendar,
  Eye,
  Folder,
  Mail,
  PieChart,
  Plus,
  ShoppingCart,
  Trello,
  Users,
} from 'react-feather';
import Logo from '../../../components/Logo';
import NavItem from './NavItem';
import AccountObj from '../../../types/Account';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { messageExists, translate } from '../../../utils/i18nHelpers';
import { NavConfig, NavConfigItems } from '../../../types/NavBarItem';
import navBarHelper from '../../../utils/navBarHelper';
import { AvailableRoles } from '../../../constants/enums';
import { hasUserAccess, userHasOneOfRole } from '../../../utils/helper';

const ACCESS_ROLES = ['Admin', 'Administrator', 'Compliance management', 'Head of Information Security Department'];

const navConfig: NavConfig[] = [
  {
    subHeaderKey: 'TenderPlatform',
    items: [
      {
        title: 'TenderStatus',
        href: '/app/tender/status',
        icon: Trello,
      },
      {
        title: 'TenderList',
        href: '/app/tender/list',
        icon: Eye,
        roles: [AvailableRoles.TENDER_LIST],
      },
      {
        title: 'TenderCreate',
        href: '/app/tender/create',
        icon: Plus,
        roles: [AvailableRoles.TENDER_CREATE],
      },
    ],
  },
  {
    subHeaderKey: 'Management',
    items: [
      {
        title: 'Users',
        icon: Users,
        href: '/app/management/users',
        roles: [AvailableRoles.USER_LIST, AvailableRoles.ROLE_LIST],
        items: [
          {
            title: 'ListUsers',
            href: '/app/management/users/list',
            roles: [AvailableRoles.USER_LIST],
          },
          {
            title: 'ListRoles',
            href: '/app/management/roles/list',
            roles: [AvailableRoles.ROLE_LIST],
          },
          {
            title: 'Access',
            href: '/app/management/access',
            roles: [AvailableRoles.ROLE_LIST],
          },
        ],
      },
      {
        title: 'Suppliers',
        icon: Folder,
        href: '/app/management/suppliers',
        items: [
          {
            title: 'SuppliersList',
            href: '/app/management/suppliers/list',
          },
          {
            title: 'SuppliersChecks',
            href: '/app/management/suppliers/checks',
          },
        ],
      },
      {
        title: 'Tenders',
        icon: Folder,
        href: '/app/management/tenders',
        items: [
          {
            title: 'TenderTypes',
            href: '/app/management/tenders/types/list',
          },
          {
            title: 'DisplayedOrderFields',
            href: '/app/management/orderFields',
          },
          {
            title: 'ContractTemplates',
            href: '/app/management/tenders/contract/templates',
          },
        ],
      },
      {
        title: 'Портал поставщика',
        icon: BookOpen,
        href: '/app/management/supplierPortal',
        items: [
          {
            title: 'FAQ',
            href: '/app/management/supplierPortal/faq',
          },
        ],
      },
      {
        title: 'Журнал логов',
        icon: Book,
        href: '/app/management/logs',
        roleNames: ACCESS_ROLES,
      },
    ],
  },
  {
    subHeaderKey: 'Reports',
    items: [
      {
        title: 'Dashboard',
        icon: PieChart,
        href: '/app/reports/dashboard',
      },
      {
        title: 'Calendar',
        href: '/app/calendar',
        icon: Calendar,
      },
    ],
  },
  {
    subHeaderKey: 'Organizer',
    items: [
      {
        title: 'Mail',
        icon: Mail,
        href: '/app/organizer/mail',
      },
      {
        title: 'Calendar',
        icon: Calendar,
        href: '/app/organizer/calendar',
      },
    ],
  },
  {
    subHeaderKey: 'SAP',
    roles: [AvailableRoles.SAPDATA_LIST],
    items: [
      {
        title: `Заявки ${new Date().getFullYear()}`,
        href: '/app/applications',
        icon: ShoppingCart,
      },
      {
        title: 'Directories',
        href: '/app/directories',
        icon: BookOpen,
        items: [],
      },
      {
        title: 'Unused',
        href: '/app/directories',
        icon: Book,
        items: [
          {
            title: 'DepartmentDirectory',
            href: '/app/unused/directories/departments/list',
          },
          {
            title: 'CurrencyCodeDirectory',
            href: '/app/unused/directories/currencies/list',
          },
          {
            title: 'ExchangeRatesDirectory',
            href: '/app/unused/directories/exchangeRates/list',
          },
          {
            title: 'EquipmentTypesDirectory',
            href: '/app/unused/directories/equipmentTypes/list',
          },
          {
            title: 'EquipmentsDirectory',
            href: '/app/unused/directories/equipments/list',
          },
          {
            title: 'UnitsDirectory',
            href: '/app/unused/directories/units/list',
          },
          {
            title: 'ProductTypesDirectory',
            href: '/app/unused/directories/productTypes/list',
          },
          {
            title: 'ProductsDirectory',
            href: '/app/unused/directories/products/list',
          },
        ],
      },
    ],
  },
];

//TODO:remove any
function renderNavItems({ items, ...rest }: any) {
  return (
    <List disablePadding>
      {items && items.reduce((acc: any[], item: NavConfigItems) => reduceChildRoutes({ acc, item, ...rest }), [])}
    </List>
  );
}

//TODO:remove any
function reduceChildRoutes({ acc, pathname, item, depth = 0 }: any) {
  const key = item.title + depth;

  if (!hasUserAccess(item.roles)) {
    return acc;
  }

  if (item.roleNames && !userHasOneOfRole(item.roleNames)) {
    return acc;
  }

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem depth={depth} icon={item.icon} key={key} info={item.info} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} key={key} info={item.info} title={item.title} />);
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 270,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  headerTitle: {},
}));

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

function NavBar({ openMobile, onMobileClose, ...props }: NavBarProps & WrappedComponentProps) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state: AccountObj) => state.account);
  const photoUrl = `${user.photoUrl}?${Date.now()}`;
  const [ready, setReady] = useState(false);
  const userName = user.firstName ? `${user.firstName} ${user.lastName}` : user.email;

  const getSAPList = useCallback(async () => {
    const sapList = navConfig.find((item: NavConfig) => {
      return item.subHeaderKey === 'SAP';
    });

    if (sapList) {
      const directories = sapList.items.find((item: NavConfigItems) => {
        return item.title === 'Directories';
      });

      const list = await navBarHelper.getNavBarListByType('SAP');
      if (directories) {
        directories.items = list;
      }
      setReady(true);
    }
  }, [setReady]);

  useEffect(() => {
    getSAPList();
  }, [getSAPList]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const getTranslations = (config: NavConfig) => {
    const id = `app:NavBar;Item;header;${config.subHeaderKey};title`;
    const isExist = messageExists(props, id);
    if (isExist) {
      return translate(props, id, config.subHeaderKey);
    } else {
      return config.subHeaderKey;
    }
  };

  if (!ready) {
    return null;
  }
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <Link component={RouterLink} to="/app/account" underline="none">
              <Avatar alt="User" className={classes.avatar} src={photoUrl} />
            </Link>
          </Box>
          <Box mt={2} textAlign="center">
            <Link component={RouterLink} to="/app/account" variant="h5" color="textPrimary" underline="none">
              {userName}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {typeof user.role !== 'string'
                ? user.role && user.role.displayName && user.role.displayName.ru
                : user.role}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {navConfig.map(config => {
            if (!hasUserAccess(config.roles)) {
              return null;
            }
            return (
              <List
                key={config.subHeaderKey}
                subheader={
                  <ListSubheader disableGutters disableSticky className={classes.headerTitle}>
                    {getTranslations(config)}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: config.items,
                  pathname: location.pathname,
                })}
              </List>
            );
          })}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

export default injectIntl(NavBar);
