/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
    CREATE_FOLDER, getFolderList,
    INITIALIZE_FOLDERS,
    REMOVE_FOLDERS,
    SET_FOLDERS,
    SWAP_FOLDERS,
    UPDATE_FOLDER
} from '../../actions/public/folderActions';
import { FolderItem } from '../../types/public/FolderList';
import _ from 'lodash';
import userFolderService from '../../publicServices/userFolderService';

interface Action {
    type: string;
    data: any;
}

interface State {
    folders: FolderItem[];
    folder?: FolderItem;
    index?: number;
}

const initialState: State = {
    folders: []
};

const folderReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case INITIALIZE_FOLDERS: {
            return produce(state, (draft) => {
                draft.folders = [];
            });
        }

        case SET_FOLDERS: {
            const { folders } = action.data;

            return produce(state, (draft) => {
                draft.folders = folders;
            });
        }

        case UPDATE_FOLDER: {
            const { folder, index } = action.data;

            return produce(state, (draft) => {
                if (folder && index !== undefined) {
                    const changedList = _.orderBy(draft.folders, ['order'], "asc");
                    changedList[index] = folder;
                    draft.folders = changedList;
                }
            });
        }

        case CREATE_FOLDER: {
            const { folder } = action.data;

            return produce(state, (draft) => {
                if (folder) {
                    draft.folders = [...draft.folders, folder];
                }
            });
        }

        case SWAP_FOLDERS: {
            return produce(state, (draft) => {
                const changedList = _.orderBy(draft.folders, ['order'], "asc");
                const currentEl = changedList.find((el)=> action.data.currentId === el.id);
                const nextEl = changedList.find((el)=> action.data.nextId === el.id);
                if (currentEl && nextEl) {
                    const currentElOrder = currentEl.order;

                    currentEl.order = nextEl.order;
                    nextEl.order = currentElOrder;
                }

                draft.folders = changedList;
            });
        }

        case REMOVE_FOLDERS: {
            return produce(state, (draft ) => {
                const { folders } = action.data;
                draft.folders = draft.folders.filter((folder: any) => {
                    if (folders.includes(folder._id)) {
                        return false;
                    }
                    return true;
                });
            });
        }

        default: {
            return state;
        }
    }
};

export default folderReducer;
