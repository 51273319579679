/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
    CLEAR_SEARCH,
    SET_SEARCH
} from '../../actions/public/searchActions';

interface Action {
    type: string;
    data: any;
}

interface State {
    search: string;
}

const initialState: State = {
    search: '',
};

const folderReducer = (state = initialState, action: Action) => {
    switch (action.type) {
        case CLEAR_SEARCH: {
            return produce(state, (draft) => {
                draft.search = '';
            });
        }

        case SET_SEARCH: {
            const { searchValue } = action.data;

            return produce(state, (draft) => {
                draft.search = searchValue;
            });
        }

        default: {
            return state;
        }
    }
};

export default folderReducer;
