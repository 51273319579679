import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ThemConfig } from '../types/Them';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { setTenderUpdates } from '../actions/utilsActions';
import AccountObj from '../types/Account';

const useStyles = makeStyles((theme: ThemConfig) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3),
  },
  absolute: {
    top: 0,
    position: 'fixed',
    left: 0,
    right: 0,
    backgroundColor: '#00000069',
    zIndex: 9999,
  },
  tenderItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 2,
    marginTop: 2,
  },
  wrapper: {
    marginBottom: 20,
  },
}));

export interface UtilsProps {
  utils: {
    showLoader: boolean;
    tenderUpdates: any;
  };
}

function TenderUpdateModal() {
  const classes = useStyles();
  const { tenderUpdates } = useSelector((state: UtilsProps) => state.utils);
  const { user } = useSelector((state: AccountObj) => state.account);
  const dispatch = useDispatch();

  if (!tenderUpdates.isModalOpen || !user?.isVerified) {
    return null;
  }

  const handleClose = () => {
    dispatch(setTenderUpdates({ ...tenderUpdates, isModalOpen: false }));
  };

  return (
    <div className={classes.absolute}>
      <Dialog open={true} onClose={handleClose} aria-labelledby="alert-dialog-title" fullWidth>
        <DialogTitle id="alert-dialog-title">Обновления по тендерам</DialogTitle>
        <DialogContent>
          {Boolean(tenderUpdates.pendingPriceDeviation && tenderUpdates.pendingPriceDeviation.length) && (
            <Box className={classes.wrapper}>
              <Typography variant="h4" color="textPrimary" align={'center'}>
                Ожидают информации по отклонению цен
              </Typography>
              {tenderUpdates.pendingPriceDeviation.map((tender: any, index: number) => {
                return (
                  <Box key={index}>
                    <Box className={classes.tenderItem}>
                      <Typography>{`${tender.id} ${tender.name}`}</Typography>
                      <Button
                        component={RouterLink}
                        size="small"
                        onClick={handleClose}
                        to={`/app/tender/edit/${tender.id}`}
                      >
                        Перейти
                      </Button>
                    </Box>
                    <Divider />
                  </Box>
                );
              })}
            </Box>
          )}
          {Boolean(tenderUpdates.pendingPaymentInfo && tenderUpdates.pendingPaymentInfo.length) && (
            <Box className={classes.wrapper}>
              <Typography variant="h4" color="textPrimary" align={'center'}>
                Ожидают информации по оплате
              </Typography>
              {tenderUpdates.pendingPaymentInfo.map((tender: any, index: number) => {
                return (
                  <Box key={index}>
                    <Box className={classes.tenderItem}>
                      <Typography>{`${tender.id} ${tender.name}`}</Typography>
                      <Button
                        component={RouterLink}
                        size="small"
                        onClick={handleClose}
                        to={`/app/tender/edit/${tender.id}`}
                      >
                        Перейти
                      </Button>
                    </Box>
                    <Divider />
                  </Box>
                );
              })}
            </Box>
          )}
          {Boolean(tenderUpdates.readyToPublish && tenderUpdates.readyToPublish.length) && (
            <Box className={classes.wrapper}>
              <Typography variant="h4" color="textPrimary" align={'center'}>
                Готовы к публикации
              </Typography>
              {tenderUpdates.readyToPublish.map((tender: any, index: number) => {
                return (
                  <Box key={index}>
                    <Box className={classes.tenderItem}>
                      <Typography>{`${tender.id} ${tender.name}`}</Typography>
                      <Button
                        component={RouterLink}
                        size="small"
                        onClick={handleClose}
                        to={`/app/tender/edit/${tender.id}`}
                      >
                        Перейти
                      </Button>
                    </Box>
                    <Divider />
                  </Box>
                );
              })}
            </Box>
          )}
          {Boolean(tenderUpdates.onVerification && tenderUpdates.onVerification.length) && (
            <Box className={classes.wrapper}>
              <Typography variant="h4" color="textPrimary" align={'center'}>
                Ожидают верификации
              </Typography>
              {tenderUpdates.onVerification.map((tender: any, index: number) => {
                return (
                  <Box key={index}>
                    <Box className={classes.tenderItem}>
                      <Typography>{`${tender.id} ${tender.name}`}</Typography>
                      <Button
                        component={RouterLink}
                        size="small"
                        onClick={handleClose}
                        to={`/app/tender/edit/${tender.id}`}
                      >
                        Перейти
                      </Button>
                    </Box>
                    <Divider />
                  </Box>
                );
              })}
            </Box>
          )}
          {Boolean(tenderUpdates.onRevision && tenderUpdates.onRevision.length) && (
            <Box className={classes.wrapper}>
              <Typography variant="h4" color="textPrimary" align={'center'}>
                На доработку
              </Typography>
              {tenderUpdates.onRevision.map((tender: any, index: number) => {
                return (
                  <Box key={index}>
                    <Box className={classes.tenderItem}>
                      <Typography>{`${tender.id} ${tender.name}`}</Typography>
                      <Button
                        component={RouterLink}
                        size="small"
                        onClick={handleClose}
                        to={`/app/tender/edit/${tender.id}`}
                      >
                        Перейти
                      </Button>
                    </Box>
                    <Divider />
                  </Box>
                );
              })}
            </Box>
          )}

          {Boolean(tenderUpdates.oapInspection && tenderUpdates.oapInspection.length) && (
            <Box className={classes.wrapper}>
              <Typography variant="h4" color="textPrimary" align={'center'}>
                Ожидают инспекции ОАП
              </Typography>
              {tenderUpdates.oapInspection.map((tender: any, index: number) => {
                return (
                  <Box key={index}>
                    <Box className={classes.tenderItem}>
                      <Typography>{`${tender.id} ${tender.name}`}</Typography>
                      <Button
                        component={RouterLink}
                        size="small"
                        onClick={handleClose}
                        to={`/app/tender/edit/${tender.id}`}
                      >
                        Перейти
                      </Button>
                    </Box>
                    <Divider />
                  </Box>
                );
              })}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default TenderUpdateModal;
