import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import accountReducer from './accountReducer';
import p_accountReducer from './public/p_accountReducer';
import utilsReducer from './utilsReducer';
import { intlReducer } from 'react-intl-redux'
import folderReducer from './public/folderReducer';
import searchReducer from './public/searchReducer';
import searchTemplateReducer from './public/searchTemplateReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  form: formReducer,
  intl: intlReducer,
  utils: utilsReducer,
  publicAccount: p_accountReducer,
  folderList: folderReducer,
  search: searchReducer,
  searchTemplate: searchTemplateReducer,
});

export default rootReducer;
