import axios from '../utils/axios';
import { API } from '../constants/constants';
import Tender from '../types/Tender';
import { NewTenderFAQ } from '../types/TenderFAQ';
import { NewTenderQuestion } from '../types/public/Tender';
import { FolderItem } from '../types/public/FolderList';

class UserFolderService {

    getFolderList = () => new Promise<any>((resolve, reject) => {
        axios.get(`${ API }/UserFolder/List`)
            .then((response) => {
                if (response.data) {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    createFolder = (folderData: FolderItem) => new Promise<number>((resolve, reject) => {
        axios.post(`${ API }/UserFolder`, folderData)
            .then((response) => {
                if (response.data) {
                    resolve(response.data.id);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    addFolderToTender = (folderId: number, tenderIds: number[]) => new Promise<number>((resolve, reject) => {
        axios.post(`${ API }/UserFolder/AddTenders`, {
            folderId,
            tenderIds,
        }).then((response) => {
                if (response.data) {
                    resolve(response.data.id);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    swapFolders = (userFolderId1: number, userFolderId2: number) => new Promise<number>((resolve, reject) => {
        axios.post(`${ API }/UserFolder/Swap`, {
            userFolderId1,
            userFolderId2,
        }).then((response) => {
                if (response.data) {
                    resolve(response.data.id);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    updateFolder = (folderData: FolderItem) => new Promise<boolean>((resolve, reject) => {
        axios.put(`${ API }/UserFolder/${folderData.id}`, folderData)
            .then((response) => {
                if (response.data) {
                    resolve(true);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });

    removeFolder = (id: string) => new Promise<boolean>((resolve, reject) => {
        axios.delete(`${ API }/UserFolder/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    resolve(true);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

const userFolderService = new UserFolderService();

export default userFolderService;
