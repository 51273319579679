import axios from '../utils/axios';
import { API } from '../constants/constants';

class TrackService {
  getLogs = (options: any) =>
    new Promise<any[]>((resolve, reject) => {
      axios
        .post(`${API}/User/Track/List`, options)
        .then(response => {
          if (response.data) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  logAction = (data: any) =>
    new Promise<boolean>((resolve, reject) => {
      axios
        .post(`${API}/User/Track`, data)
        .then(response => {
          resolve(true);
        })
        .catch(error => {
          reject(error.data.message);
        });
    });
}

const trackService = new TrackService();

export default trackService;
