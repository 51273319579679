import { Dispatch } from 'redux';
import authService from '../services/authService';
import User from '../types/User';
import { logUserAction } from '../utils/helper';
import { UserActions } from '../constants/enums';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const SILENT_LOGIN = 'SILENT_LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const VERIFIED_USER = 'VERIFIED_USER';
export const USER_PHOTO_UPLOADED = 'USER_PHOTO_UPLOADED';

export function login(email: string, password: string, rememberMe: boolean) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });
      const isVerified: Boolean = await authService.login(email, password, rememberMe);
      const user: User = await authService.getCurrentUser();

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user: {
            ...user,
            isVerified,
          },
        },
      });
      authService.getIp().then(ip => {
        logUserAction(UserActions.Login, { ip });
      });
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user: User) {
  return (dispatch: Dispatch) =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user: {
          ...user,
        },
      },
    });
}

export function logout() {
  return async (dispatch: Dispatch) => {
    authService.logout();
    dispatch({
      type: LOGOUT,
    });
  };
}

export function updateProfile(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      await authService.updateCurrentUser(data);
      await dispatch({
        type: UPDATE_PROFILE,
        payload: {
          data,
        },
      });
    } catch (e) {
      throw e;
    }
  };
}

export function verify(code: string, userId: number, email: string, option: string) {
  return async (dispatch: Dispatch) => {
    try {
      const isVerified: boolean = await authService.verify(code, userId, email, option);
      await dispatch({
        type: VERIFIED_USER,
        payload: {
          isVerified,
        },
      });
    } catch (error) {
      //dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function uploadPhoto(photo: FormData) {
  return async (dispatch: Dispatch) => {
    try {
      const url: string = await authService.uploadPhoto(photo);
      await dispatch({
        type: USER_PHOTO_UPLOADED,
        payload: {
          url,
        },
      });
    } catch (error) {
      throw error;
    }
  };
}

export function removePhoto() {
  return async (dispatch: Dispatch) => {
    try {
      await authService.removePhoto();
      await dispatch({
        type: USER_PHOTO_UPLOADED,
        payload: {
          url: '',
        },
      });
    } catch (error) {
      throw error;
    }
  };
}
