/* eslint-disable no-param-reassign */
import produce from 'immer';

import { DynamicObject } from '../../types/DynamicObject';
import User from '../../types/public/User';
import {
  P_LOGIN_FAILURE,
  P_LOGIN_REQUEST, P_LOGIN_SUCCESS,
  P_LOGOUT,
  P_SILENT_LOGIN, P_USER_PHOTO_UPLOADED, P_VERIFIED_USER
} from '../../actions/public/authActions';
import { USER_PHOTO_UPLOADED } from '../../actions/accountActions';

interface Action {
  type: string;
  data: DynamicObject;
}

interface State {
  user: {} | null;
}

const initialState: State = {
  user: null
};

const p_accountReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case P_LOGIN_REQUEST: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case P_LOGIN_SUCCESS: {
      const { user } = action.data;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case P_VERIFIED_USER: {
      const { isVerified } = action.data;

      return produce(state, (draft) => {
        draft.user = {
          ...draft.user,
          isVerified
        };
      });
    }

    case P_LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case P_LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case P_SILENT_LOGIN: {
      const { user } = action.data;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case P_USER_PHOTO_UPLOADED: {
      const { url } = action.data;

      return produce(state, (draft) => {
        draft.user = {
          ...draft.user,
          photoUrl: url,
        };
      });
    }

    default: {
      return state;
    }
  }
};

export default p_accountReducer;
