import { Dispatch } from 'redux';
import { FolderItem } from '../../types/public/FolderList';
import userFolderService from '../../publicServices/userFolderService';


export const INITIALIZE_FOLDERS = 'INITIALIZE_FOLDERS';
export const SET_FOLDERS = 'SET_FOLDERS';
export const UPDATE_FOLDER = 'UPDATE_FOLDER';
export const CREATE_FOLDER = 'CREATE_FOLDER';
export const SWAP_FOLDERS = 'SWAP_FOLDERS';
export const REMOVE_FOLDERS = 'REMOVE_FOLDERS';

export function getFolderList() {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({ type: INITIALIZE_FOLDERS });
            const folderList: FolderItem[] = await userFolderService.getFolderList();

            dispatch({
                type: SET_FOLDERS,
                data: {
                    folders: folderList,
                }
            });
        } catch (error) {
            dispatch({ type: INITIALIZE_FOLDERS });
            throw error;
        }
    };
}

export function updateFolder(folder: FolderItem, index: number) {
    return async (dispatch: Dispatch) => {
        try {
            await userFolderService.updateFolder(folder);
            await dispatch({
                type: UPDATE_FOLDER,
                data: {
                    folder,
                    index
                }
            });
        } catch (error) {
            throw error;
        }
    };
}

export function swapFolders(currentId: number, nextId: number) {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({
                type: SWAP_FOLDERS,
                data: {
                    currentId,
                    nextId
                }
            });
            await userFolderService.swapFolders(currentId, nextId);
        } catch (error) {
            throw error;
        }
    };
}

export function createNewFolder(folder: FolderItem) {
    return async (dispatch: Dispatch) => {
        try {
            const id = await userFolderService.createFolder(folder);
            folder.id = id || undefined;
            await dispatch({
                type: CREATE_FOLDER,
                data: {
                    folder
                }
            });
        } catch (error) {
            throw error;
        }
    };
}

export function removeFolders(folders: string[]) {
    return async (dispatch: Dispatch) => {
        try {
            await userFolderService.removeFolder(folders[0]);
            await dispatch({
                type: REMOVE_FOLDERS,
                data: {
                    folders
                }
            });
        } catch (error) {
            throw error;
        }
    };
}
