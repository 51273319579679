export const HOST_API = {
  DEFAULT: '/api',
  DEV_MANAGER: 'https://uktmk-dev-procurement-manager.azurewebsites.net/api',
  TEST_MANAGER: 'https://uktmk-test-procurement-manager.azurewebsites.net/api',

  DEV_SUPPLIER: 'https://uktmk-dev-procurement-supplier.azurewebsites.net/api',
  TEST_SUPPLIER: 'https://uktmk-test-procurement-supplier.azurewebsites.net/api',

  PROD_MANAGER: 'https://manager.purchase.uktmp.kz/api',
  PROD_SUPPLIER: 'https://purchase.uktmp.kz/api',

  OLD_DEV_MANAGER: 'http://stage.procurement-manager.uktmk.tk/api',
  OLD_DEV_SUPPLIER: 'http://stage.procurement-supplier.uktmk.tk/api',
};

// export const API = '/api';
// export const API = 'https://dev.procurement-manager.uktmk.tk/api';
// export const API = 'https://dev.procurement-supplier.uktmk.tk/api';
// export const API = 'http://manager.purchase.uktmp.kz/api';
// export const API = 'http://supplier.purchase.uktmp.kz/api';
// export const API = 'http://stage.procurement-manager.uktmk.tk/api'; //old
// export const API = 'http://stage.procurement-supplier.uktmk.tk/api'; //old

const env = process.env.REACT_APP_STAGING || 'DEFAULT'; //possible values: sandpit, test, dev, preprod;

// @ts-ignore
export const API = HOST_API[env];

export const PHONE_REGEX_UA = /^\+?3?8?(0\d{9})$/;
export const PHONE_REGEX_KZ = /^\+?77(\d{9})$/;
export const LATIN_CHARACTERS = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/;
