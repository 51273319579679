import axios from '../utils/axios';
import { API } from '../constants/constants';
import NavBarItem, {NavConfigItems} from '../types/NavBarItem';
import {store} from "../store";
import {triggerLoader, updateSAPDate} from "../actions/utilsActions";
import moment from "moment";

class NavBarService {

    getSapList = () => new Promise<NavBarItem[]>((resolve, reject) => {
        axios.get(`${ API }/Dictionary`)
            .then((response) => {
                if (response.data) {
                    const newData: any = {};
                    response.data.forEach((item: any) => {
                        newData[item.name] = item.lastUpdateTime ? item.lastUpdateTime : moment().startOf('day').toString();
                    });
                    store.dispatch(updateSAPDate(newData) as any);
                    resolve(response.data);
                } else {
                    reject(response.data.error);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

const navBarService = new NavBarService();

export default navBarService;
