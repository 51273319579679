import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen';
import GuestRoute from './components/GuestRoute';
import AuthRoute from './components/AuthRoute';
import DashboardLayout from './layouts/DashboardLayout';
import Error404View from './views/pages/Error404View';
import NotImplemented from './views/pages/NotImplemented';
import Auth from './components/Auth';
import AccessRoute from './components/AccessRoute';
import { AvailableRoles } from './constants/enums';

function Routes() {
  return (
    <Auth>
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          <Redirect exact from="/" to="/login" />
          <GuestRoute path="/login" component={lazy(() => import('./views/auth/LoginView'))} />
          <Route path="/verification" component={lazy(() => import('./views/auth/VerificationView'))} />
          <AuthRoute
            path="/app"
            render={(props: any) => (
              <DashboardLayout {...props}>
                {/*<Suspense fallback={<LoadingScreen />}>*/}
                <Switch>
                  <Redirect exact from="/app" to="/app/reports/dashboard" />
                  <Route
                    path="/app/reports/dashboard"
                    component={lazy(() => import('./views/reports/DashboardView'))}
                  />
                  <AccessRoute
                    path="/app/management/users/list"
                    roles={[AvailableRoles.USER_LIST]}
                    component={lazy(() => import('./views/management/UserListView'))}
                  />
                  <AccessRoute
                    path={'/app/management/users/new'}
                    roles={[AvailableRoles.USER_CREATE]}
                    component={lazy(() => import('./views/management/UserCreateEditView'))}
                  />
                  <AccessRoute
                    path="/app/management/users/:userId/edit"
                    roles={[AvailableRoles.USER_EDIT]}
                    component={lazy(() => import('./views/management/UserCreateEditView'))}
                  />
                  <Route path="/app/account" component={lazy(() => import('./views/pages/AccountView'))} />
                  <AccessRoute
                    path="/app/management/roles/list"
                    roles={[AvailableRoles.ROLE_LIST]}
                    component={lazy(() => import('./views/management/RoleListView'))}
                  />
                  <AccessRoute
                    path="/app/management/roles/new"
                    roles={[AvailableRoles.ROLE_CREATE]}
                    component={lazy(() => import('./views/management/RoleCreateEditView'))}
                  />
                  <AccessRoute
                    path="/app/management/roles/:roleId/edit"
                    roles={[AvailableRoles.ROLE_EDIT]}
                    component={lazy(() => import('./views/management/RoleCreateEditView'))}
                  />
                  <AccessRoute
                    path="/app/management/access"
                    roles={[AvailableRoles.ROLE_LIST]}
                    component={lazy(() => import('./views/management/AccessView'))}
                  />
                  <AccessRoute
                    path="/app/directories/:type"
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/directories/GenericDirectoryListView'))}
                  />
                  <AccessRoute
                    path="/app/unused/directories/departments/list"
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/directories/DepartmentListView'))}
                  />
                  <AccessRoute
                    path="/app/unused/directories/departments/:depId/:edit"
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/directories/DepartmentCreateEditView'))}
                  />
                  <AccessRoute
                    path="/app/unused/directories/departments/:depId"
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/directories/DepartmentCreateEditView'))}
                  />
                  <AccessRoute
                    path="/app/unused/directories/departments/new"
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/directories/DepartmentCreateEditView'))}
                  />
                  <AccessRoute
                    path="/app/unused/directories/currencies/list"
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/directories/CurrencyListView'))}
                  />
                  <AccessRoute
                    path={[
                      '/app/unused/directories/currencies/new',
                      '/app/unused/directories/currencies/:currId/:edit',
                      '/app/unused/directories/currencies/:currId',
                    ]}
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/directories/CurrencyCreateEditView'))}
                  />
                  <AccessRoute
                    path="/app/unused/directories/exchangeRates/list"
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/directories/ExchangeRatesListView'))}
                  />
                  <AccessRoute
                    path={[
                      '/app/unused/directories/exchangeRates/new',
                      '/app/unused/directories/exchangeRates/:rateId/:edit',
                      '/app/unused/directories/exchangeRates/:rateId',
                    ]}
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/directories/ExchangeRatesCreateEditView'))}
                  />
                  <AccessRoute
                    path="/app/unused/directories/equipmentTypes/list"
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/directories/EquipmentTypesListView'))}
                  />
                  <AccessRoute
                    path={[
                      '/app/unused/directories/equipmentTypes/new',
                      '/app/unused/directories/equipmentTypes/:id/:edit',
                      '/app/unused/directories/equipmentTypes/:id',
                    ]}
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/directories/EquipmentTypesCreateEditView'))}
                  />
                  <AccessRoute
                    path="/app/unused/directories/equipments/list"
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/directories/EquipmentListView'))}
                  />
                  <AccessRoute
                    path={[
                      '/app/unused/directories/equipments/new',
                      '/app/unused/directories/equipments/:id/:edit',
                      '/app/unused/directories/equipments/:id',
                    ]}
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/directories/EquipmentCreateEditView'))}
                  />
                  <AccessRoute
                    path="/app/unused/directories/units/list"
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/directories/UnitsListView'))}
                  />
                  <AccessRoute
                    path={[
                      '/app/unused/directories/units/new',
                      '/app/unused/directories/units/:id/:edit',
                      '/app/unused/directories/units/:id',
                    ]}
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/directories/UnitsCreateEditView'))}
                  />
                  <AccessRoute
                    path="/app/unused/directories/productTypes/list"
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/directories/ProductTypesListView'))}
                  />
                  <AccessRoute
                    path={[
                      '/app/unused/directories/productTypes/new',
                      '/app/unused/directories/productTypes/:id/:edit',
                      '/app/unused/directories/productTypes/:id',
                    ]}
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/directories/ProductTypesCreateEditView'))}
                  />
                  <AccessRoute
                    path="/app/unused/directories/products/list"
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/directories/ProductsListView'))}
                  />
                  <AccessRoute
                    path={[
                      '/app/unused/directories/products/new',
                      '/app/unused/directories/products/:id/:edit',
                      '/app/unused/directories/products/:id',
                    ]}
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/directories/ProductsCreateEditView'))}
                  />
                  <Route
                    path="/app/management/suppliers/list"
                    component={lazy(() => import('./views/management/SupplierListView'))}
                  />
                  <Route
                    path={['/app/management/suppliers/checks']}
                    component={lazy(() => import('./views/management/SupplierChecksView'))}
                  />
                  <Route
                    path={['/app/management/suppliers/check/:id']}
                    component={lazy(() => import('./views/management/SupplierDetailsView'))}
                  />
                  <AccessRoute
                    path="/app/procurement/materials"
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/procurement/MaterialListView'))}
                  />
                  <AccessRoute
                    path="/app/procurement/material/:id"
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/procurement/MaterialView'))}
                  />
                  <AccessRoute
                    path="/app/procurement/services"
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/procurement/ServicesListView'))}
                  />
                  <AccessRoute
                    path="/app/procurement/service/:id"
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/procurement/ServicesView'))}
                  />
                  <AccessRoute
                    path="/app/applications"
                    roles={[AvailableRoles.SAPDATA_LIST]}
                    component={lazy(() => import('./views/applications/ApplicationListView'))}
                  />
                  <AccessRoute
                    path="/app/tender/list"
                    roles={[AvailableRoles.TENDER_LIST]}
                    component={lazy(() => import('./views/tenders/TenderListView'))}
                  />
                  <AccessRoute
                    path="/app/tender/status"
                    roles={[AvailableRoles.TENDER_LIST]}
                    component={lazy(() => import('./views/tenders/TenderStatusView'))}
                  />
                  <Route
                    path="/app/management/tenders/types/list"
                    component={lazy(() => import('./views/management/TenderTypes/TenderTypesListView'))}
                  />
                  <Route
                    path="/app/management/tenders/contract/templates"
                    component={lazy(() => import('./views/management/TenderTypes/ContractTemplatesView'))}
                  />
                  <Route
                    path={['/app/management/tenders/types/new', '/app/management/tenders/types/:id/:edit']}
                    component={lazy(() => import('./views/management/TenderTypes/TenderTypeCreateView'))}
                  />
                  <Route
                    path={['/app/management/logs']}
                    component={lazy(() => import('./views/management/LogsListView'))}
                  />
                  <AccessRoute
                    path="/app/tender/create"
                    roles={[AvailableRoles.TENDER_CREATE]}
                    component={lazy(() => import('./views/tenders/TenderCreateView'))}
                  />
                  <Route path="/app/tender/edit/:id" component={lazy(() => import('./views/tenders/TenderEditView'))} />
                  {/*<Route
                                        path="/app/management/tenders/questionnaireTemplates/list"
                                        component={ lazy(() => import('./views/management/TenderQTemplateListView')) }
                                    />
                                    <Route
                                        path={ [
                                            "/app/management/tenders/questionnaireTemplates/new",
                                            "/app/management/tenders/questionnaireTemplates/:id/:edit",
                                            "/app/management/tenders/questionnaireTemplates/:id"] }
                                        component={ lazy(() => import('./views/management/TenderQTemplateCreateEditView')) }
                                    />*/}
                  {/*<Route
                                        path="/app/management/tenders/faq/list"
                                        component={ lazy(() => import('./views/management/TenderFAQsListView')) }
                                    />
                                    <Route
                                        path={ [
                                            "/app/management/tenders/faq/new",
                                            "/app/management/tenders/faq/:id/:edit",
                                            "/app/management/tenders/faq/:id"] }
                                        component={ lazy(() => import('./views/management/TenderFAQsCreateEditView')) }
                                    />*/}
                  <Route
                    path="/app/management/supplierPortal/faq"
                    component={lazy(() => import('./views/management/SupplierFAQListView'))}
                  />
                  <Route
                    path="/app/management/priceDeviation"
                    component={lazy(() => import('./views/management/PriceDeviation'))}
                  />
                  <Route path="/app/organizer/mail" component={lazy(() => import('./views/mail/MailView'))} />
                  <Route
                    path="/app/organizer/calendar"
                    component={lazy(() => import('./views/calendar/CalendarView'))}
                  />
                  <Route path="/app/calendar" component={lazy(() => import('./views/calendar/CalendarView'))} />
                  {/*<Route
                                        path="/app/management/tenders/documentTemplates/attached/list"
                                        component={ lazy(() => import('./views/management/TenderDocuments/AttachedDocumentsListView')) }
                                    />
                                    <Route
                                        path={ [
                                            "/app/management/tenders/documentTemplates/attached/new",
                                            "/app/management/tenders/documentTemplates/attached/:id/:edit",
                                            "/app/management/tenders/documentTemplates/attached/:id"] }
                                        component={ lazy(() => import('./views/management/TenderDocuments/AttachedDocumentsCreateEditView')) }
                                    />
                                    <Route
                                        path="/app/management/tenders/documentTemplates/requested/list"
                                        component={ lazy(() => import('./views/management/TenderDocuments/RequestedDocumentsListView')) }
                                    />
                                    <Route
                                        path={ [
                                            "/app/management/tenders/documentTemplates/requested/new",
                                            "/app/management/tenders/documentTemplates/requested/:id/:edit",
                                            "/app/management/tenders/documentTemplates/requested/:id"] }
                                        component={ lazy(() => import('./views/management/TenderDocuments/RequestedDocumentsCreateEditView')) }
                                    />*/}
                  <Route
                    path="/app/management/orderFields"
                    component={lazy(() => import('./views/management/DisplayedOrderFieldsView'))}
                  />
                  <Route component={NotImplemented} />
                </Switch>
                {/*</Suspense>*/}
              </DashboardLayout>
            )}
          />
          <Route component={Error404View} />
        </Switch>
      </Suspense>
    </Auth>
  );
}

export default Routes;
