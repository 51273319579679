import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { ThemConfig } from '../types/Them';
import { useDispatch, useSelector } from 'react-redux';
import { setRequestedDocs } from '../actions/utilsActions';
import { ReqDocItem } from '../types/public/Documents';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: ThemConfig) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3),
  },
  absolute: {
    top: 0,
    position: 'fixed',
    left: 0,
    right: 0,
    backgroundColor: '#00000069',
    zIndex: 9999,
  },
  tenderItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 2,
    marginTop: 2,
  },
  wrapper: {
    marginBottom: 20,
  },
}));

export interface UtilsProps {
  utils: {
    showLoader: boolean;
    tenderUpdates: any;
    requestedDocs: any;
  };
}

function SupplierRequestedDocsModal() {
  const classes = useStyles();
  const history = useHistory();

  const { requestedDocs } = useSelector((state: UtilsProps) => state.utils);
  const dispatch = useDispatch();

  if (!requestedDocs.isModalOpen) {
    return null;
  }

  const handleClose = () => {
    dispatch(setRequestedDocs({ list: requestedDocs.list, isModalOpen: false }));
  };
  const handleOpen = () => {
    dispatch(setRequestedDocs({ list: requestedDocs.list, isModalOpen: false }));
    history.push('/docs');
  };

  if (!requestedDocs?.list?.length) return null;

  return (
    <div className={classes.absolute}>
      <Dialog open={true} onClose={handleClose} aria-labelledby="alert-dialog-title" fullWidth>
        <DialogTitle id="alert-dialog-title">Важные сообщения</DialogTitle>
        <DialogContent>
          У вас есть важное сообщение от организатора тендера:
          <ul style={{ marginLeft: 20 }}>
            {requestedDocs.list.map((item: ReqDocItem) => (
              <li style={{ padding: '5px' }}>
                <b>{item.message}</b>
              </li>
            ))}
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Закрыть
          </Button>
          <Button onClick={handleOpen} variant="contained" color="primary">
            Перейти
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SupplierRequestedDocsModal;
