import { Dispatch } from 'redux';

export const TRIGGER_LOADER = 'TRIGGER_LOADER';
export const TRIGGER_MENU_UPDATE = 'TRIGGER_MENU_UPDATE';
export const UPDATE_SAP_DATE = 'UPDATE_SAP_DATE';
export const SET_TENDER_UPDATES = 'SET_TENDER_UPDATES';
export const SET_REQUESTED_DOCS = 'SET_REQUESTED_DOCS';

export function triggerLoader(showLoader: boolean) {
  return (dispatch: Dispatch) =>
    dispatch({
      type: TRIGGER_LOADER,
      payload: {
        showLoader,
      },
    });
}

export function triggerUpdateMenu() {
  return (dispatch: Dispatch) =>
    dispatch({
      type: TRIGGER_MENU_UPDATE,
    });
}

export function updateSAPDate(newDate: any) {
  return (dispatch: Dispatch) =>
    dispatch({
      type: UPDATE_SAP_DATE,
      payload: {
        lastSapUpdatedDate: newDate,
      },
    });
}

export function setTenderUpdates(updates: any) {
  return (dispatch: Dispatch) =>
    dispatch({
      type: SET_TENDER_UPDATES,
      payload: {
        tenderUpdates: updates,
      },
    });
}

export function setRequestedDocs(docs: any) {
  return (dispatch: Dispatch) =>
    dispatch({
      type: SET_REQUESTED_DOCS,
      payload: {
        requestedDocs: docs,
      },
    });
}
