export enum THEMES {
  LIGHT = 'LIGHT',
  ONE_DARK = 'ONE_DARK',
  UNICORN = 'UNICORN',
}

export enum DIRECTION {
  ltr = 'ltr',
  rtl = 'rtl',
}

export enum LANGS {
  EN = 'en',
  RU = 'ru',
  KZ = 'kz',
}

export enum SupplierAllowStatus {
  ALLOWED = 'ALLOWED',
  PRICE_NOT_ALLOWED = 'PRICE_NOT_ALLOWED',
  VIOLATIONS_NOT_ALLOWED = 'VIOLATIONS_NOT_ALLOWED',
  PRICE_AND_VIOLATIONS_NOT_ALLOWED = 'PRICE_AND_VIOLATIONS_NOT_ALLOWED',
  NOT_ALLOWED = 'NOT_ALLOWED',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
}

export enum DocumentsType {
  OWN = 'Own',
  REQUESTED = 'Requested',
}

export enum TenderFilterObjectType {
  Number = 'n',
  Date = 'd',
}

export enum TenderStatus {
  Created = 'Created',
  Editing = 'Editing',
  Blocked = 'Blocked',
  Deleted = 'Deleted',
  OnApproval = 'OnApproval',
  Published = 'Published',
  Completed = 'Completed',
  Archived = 'Archived',
  SubmissionCompleted = 'SubmissionCompleted',
  PendingPaymentInfo = 'PendingPaymentInfo',
  OnVerification = 'OnVerification',
  ReadyToPublish = 'ReadyToPublish',
  OnRevision = 'OnRevision',
  PendingPriceDeviation = 'PendingPriceDeviation',
  OAPInspection = 'OAPInspection',
}

export enum AvailableRoles {
  USER_CREATE = 'User.Create',
  USER_LIST = 'User.List',
  USER_EDIT = 'User.Edit',
  USER_DELETE = 'User.Delete',
  ROLE_CREATE = 'Role.Create',
  ROLE_LIST = 'Role.List',
  ROLE_EDIT = 'Role.Edit',
  ROLE_DELETE = 'Role.Delete',
  REPORT_CREATE = 'Report.Create',
  REPORT_LIST = 'Report.List',
  SAPDATA_VIEW = 'SAPData.View',
  SAPDATA_LIST = 'SAPData.List',
  TENDER_CREATE = 'Tender.Create',
  TENDER_LIST = 'Tender.List',
  TENDER_EDIT = 'Tender.Edit',
  TENDER_DELETE = 'Tender.Delete',
  TENDER_OPEN = 'Tender.Open',
  TENDER_CLOSE = 'Tender.Close',
  TENDER_SIGN = 'Tender.Sign',
  TENDER_VERIFICATION = 'Tender.Verification',
  TENDER_PENDING_PAYMENT_INFO = 'Tender.PendingPaymentInfo',
  TENDER_PENDING_PRICE_DEVIATION = 'Tender.PendingPriceDeviation',
  TENDER_OAP_INSPECTION = 'Tender.OAPInspection',
  TENDER_PRICE = 'Tender.Price',
  QUESTIONNAIRE_CREATE = 'Questionnaire.Create',
  QUESTIONNAIRE_EDIT = 'Questionnaire.Edit',
  QUESTIONNAIRE_DELETE = 'Questionnaire.Delete',
}

export enum SupplierApproveStatus {
  NotApproved = 'NotApproved',
  PartialApproved = 'PartialApproved',
  ApprovedByYou = 'ApprovedByYou',
  FullApproved = 'FullApproved',
}

export enum ContractTemplateType {
  Basic = 'Basic',
  WithTolerance = 'WithTolerance',
  WithPrepayment = 'WithPrepayment',
  WithToleranceAndPrepayment = 'WithToleranceAndPrepayment',
}

export enum UserActions {
  Login = 'Login',
  TenderCreate = 'TenderCreate',
  SetTenderPrice = 'SetTenderPrice',
  TenderFinancialDepartment = 'TenderFinancialDepartment',
  SearchSupplier = 'SearchSupplier',
  TenderOAP = 'TenderOAP',
  ManualViolationReport = 'ManualViolationReport',
  TenderVerification = 'TenderVerification',
  TenderPublication = 'TenderPublication',
  TenderEdit = 'TenderEdit',
  ChangeTenderDates = 'ChangeTenderDates',
  TenderDelete = 'TenderDelete',
  TenderForApproval = 'TenderForApproval',
  SetTenderWinner = 'SetTenderWinner',
  TenderTypeCreate = 'TenderTypeCreate',
  TenderTypeDelete = 'TenderTypeDelete',
  TenderTypeEdit = 'TenderTypeEdit',
  RoleAdd = 'RoleAdd',
  RoleEdit = 'RoleEdit',
  RoleDelete = 'RoleDelete',
  UserAdd = 'UserAdd',
  UserEdit = 'UserEdit',
  UserDelete = 'UserDelete',
  ManualApplicationsSAPSync = 'ManualApplicationsSAPSync',
}
