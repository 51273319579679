import jwtDecode from 'jwt-decode';
import axios, { uninterceptedAxiosInstance } from '../utils/axios';
import { API } from '../constants/constants';
import { triggerLoader } from '../actions/utilsActions';
import { store } from '../store';
import User, { UpdateUserProps } from '../types/User';

// let count = Number(localStorage.getItem('count') || 0);
class AuthService {
  setAxiosInterceptors = ({ onLogout }: { onLogout: Function }) => {
    axios.interceptors.response.use(
      response => {
        // count--;
        // localStorage.setItem('count', count.toString());

        // if (!count) {
        store.dispatch(triggerLoader(false) as any);
        // }
        return response;
      },
      async error => {
        store.dispatch(triggerLoader(false) as any);
        // count--;
        // localStorage.setItem('count', count.toString());
        if (error.response && error.response.status === 401) {
          try {
            await this.loginInWithToken();
          } catch (e) {
            this.setSession(null);

            if (onLogout) {
              onLogout();
            }
          }
        }

        return Promise.reject(error.response);
      }
    );
    axios.interceptors.request.use(
      response => {
        store.dispatch(triggerLoader(true) as any);
        // count++;
        // localStorage.setItem('count', count.toString());

        return response;
      },
      error => {
        store.dispatch(triggerLoader(false) as any);
        // count--;
        // localStorage.setItem('count', count.toString());

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (!this.isValidToken(accessToken)) {
      this.setSession(null);
    }
  }

  login = (emailAddress: string, password: string, rememberMe: boolean = false) =>
    new Promise<Boolean>((resolve, reject) => {
      axios
        .post(`${API}/Account/Token`, { email: emailAddress, password })
        .then(response => {
          if (response.data) {
            this.setSession(
              response.data.access_token,
              response.data.token_type,
              rememberMe,
              emailAddress,
              response.data.refresh_token
            );
            resolve(Boolean(response.data.is_verified));
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  getCurrentUser = () =>
    new Promise<User>((resolve, reject) => {
      axios
        .get(`${API}/Account`)
        .then(response => {
          if (response.data) {
            const data = response.data as User;
            resolve(data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  updateCurrentUser = (data: UpdateUserProps) =>
    new Promise<Boolean>((resolve, reject) => {
      axios
        .put(`${API}/Account`, data)
        .then(response => {
          if (response.data) {
            localStorage.setItem('email', data.email);
            resolve(true);
          } else {
            reject(false);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  changePassword = (currentPassword: string, newPassword: string) =>
    new Promise<boolean>((resolve, reject) => {
      axios
        .post(`${API}/Account/ChangePassword`, {
          currentPassword,
          newPassword,
        })
        .then(response => {
          if (response.status === 200) {
            resolve(true);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  loginInWithToken = () =>
    new Promise<Boolean>((resolve, reject) => {
      const refreshToken: string | null = this.getRefreshToken();
      const accessToken: string | null = this.getAccessToken();
      const email = localStorage.getItem('email');
      if (refreshToken && accessToken && email) {
        axios
          .post(`${API}/Account/Token`, { email, refreshToken })
          .then(response => {
            if (response.data) {
              const rememberMe = Boolean(localStorage.getItem('rememberMe')) || false;
              this.setSession(
                response.data.access_token,
                response.data.token_type,
                rememberMe,
                email,
                response.data.refresh_token
              );
              resolve(Boolean(response.data.is_verified));
            } else {
              reject(response.data.error);
            }
          })
          .catch(error => {
            reject(error);
          });
      } else {
        reject();
      }
    });

  logout = () => {
    this.setSession(null);
  };

  setSession = (
    accessToken: string | null,
    tokenType?: string,
    rememberMe?: boolean,
    email?: string,
    refreshToken?: string
  ) => {
    if (accessToken && refreshToken && email) {
      localStorage.setItem('accessToken', `${tokenType} ${accessToken}`);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('email', email);
      axios.defaults.headers.common.Authorization = `${tokenType} ${accessToken}`;
      uninterceptedAxiosInstance.defaults.headers.common.Authorization = `${tokenType} ${accessToken}`;
      if (rememberMe) {
        localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
      } else {
        localStorage.removeItem('rememberMe');
      }
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      delete axios.defaults.headers.common.Authorization;
      delete uninterceptedAxiosInstance.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');
  getRefreshToken = () => localStorage.getItem('refreshToken');

  isValidToken = (accessToken: string) => {
    if (!accessToken) {
      return false;
    }

    const { exp } = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return exp > currentTime;
  };

  isAuthenticated = () => !!this.getAccessToken();

  sendVerification = (userId: number, email: string, phone: string, option: string) =>
    new Promise<boolean>((resolve, reject) => {
      axios
        .post(`${API}/Account/SendVerify${option}`, {
          userId,
          email,
          phone,
        })
        .then(response => {
          if (response.status === 200) {
            resolve(true);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  verify = (code: string, userId: number, email: string, option: string) =>
    new Promise<boolean>((resolve, reject) => {
      axios
        .post(`${API}/Account/Verify${option}`, {
          code,
          email,
          userId,
        })
        .then(response => {
          if (response.status === 200) {
            resolve(true);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  uploadPhoto = (photo: FormData) =>
    new Promise<string>((resolve, reject) => {
      axios
        .post(`${API}/Account/UploadPhoto`, photo)
        .then(response => {
          if (response.data) {
            resolve(response.data.photoUrl);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  removePhoto = () =>
    new Promise<boolean>((resolve, reject) => {
      axios
        .delete(`${API}/Account/UploadPhoto`)
        .then(response => {
          if (response.data) {
            resolve(true);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  getIp = () =>
    new Promise<string>((resolve, reject) => {
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
          if (data.ip) {
            resolve(data.ip);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  resetPassword = (email: string | null = null, phone: string | null = null, option: string) =>
    new Promise<any>((resolve, reject) => {
      axios
        .post(`${API}/Account/RecoveryPassword`, {
          email: option === 'Email' ? email : null,
          phone: option === 'Phone' ? phone : null,
        })
        .then(response => {
          if (response.status === 200) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  confirmResetPassword = (password: string, userId: string, code: string) =>
    new Promise<boolean>((resolve, reject) => {
      axios
        .post(`${API}/Account/RecoveryPassword/Confirm`, {
          password,
          userId,
          code,
        })
        .then(response => {
          if (response.status === 200) {
            resolve(true);
          } else {
            reject(response.data.error);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
}

const authService = new AuthService();

export default authService;
