import produce from 'immer';
import {
  TRIGGER_LOADER,
  UPDATE_SAP_DATE,
  SET_TENDER_UPDATES,
  SET_REQUESTED_DOCS,
  TRIGGER_MENU_UPDATE,
} from '../actions/utilsActions';

interface Action {
  type: string;
  payload: State;
}

interface State {
  showLoader: boolean;
  updateMenuCount: number;
  lastSapUpdatedDate: any;
  tenderUpdates: any;
  requestedDocs: any;
}

const initialState: State = {
  showLoader: false,
  updateMenuCount: 0,
  lastSapUpdatedDate: {},
  tenderUpdates: {},
  requestedDocs: {},
};

const utilsReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case TRIGGER_LOADER: {
      const { showLoader } = action.payload;

      return produce(state, draft => {
        draft.showLoader = showLoader;
      });
    }

    case TRIGGER_MENU_UPDATE: {
      return produce(state, draft => {
        draft.updateMenuCount = draft.updateMenuCount + 1;
      });
    }

    case UPDATE_SAP_DATE: {
      const { lastSapUpdatedDate } = action.payload;

      return produce(state, draft => {
        draft.lastSapUpdatedDate = lastSapUpdatedDate;
      });
    }

    case SET_TENDER_UPDATES: {
      const { tenderUpdates } = action.payload;

      return produce(state, draft => {
        draft.tenderUpdates = tenderUpdates;
      });
    }

    case SET_REQUESTED_DOCS: {
      const { requestedDocs } = action.payload;

      return produce(state, draft => {
        draft.requestedDocs = { ...draft.requestedDocs, ...requestedDocs };
      });
    }

    default: {
      return state;
    }
  }
};

export default utilsReducer;
